import FielderLogo from "~/components/FielderLogo"
import { useTranslation } from "react-i18next"

export default function PoweredByFielder() {
  let { t } = useTranslation(["common"])

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2">
      <span>{t("poweredBy")}</span>
      <a href="https://fielderapp.com" target="_blank" rel="noreferrer">
        <FielderLogo width={96} />
      </a>
    </div>
  )
}
